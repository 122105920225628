/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
import React, { useEffect } from 'react';
import { Text } from '@Components/Typography/styles';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import useResponsive from '@Hooks/useMediaQuery';
import MousescrollIcon from '@Assets/icons/mouse-scroll.svg';
import Carousel from '@Components/Carousel';
import { Helmet } from 'react-helmet';
import { canonicals } from '@Helpers';
import AuthenticPage, { Img, Banner } from './styles';
import { slider1Data, slider2Data, slider3Data, slider4Data, slider5Data } from './data';

const Authentic = () => {
    const { t } = useTranslation('common');

    const { sm } = useResponsive();

    return (
        <AuthenticPage>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{t('helmet.title.authentic')}</title>
                <link rel="canonical" href={canonicals.authentic} />
            </Helmet>
            <Banner
                sm={sm}
                className="padding-horizontal padding-vertical"
                backgroundImg="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs1.jpg?alt=media&token=d3a9b2aa-922c-40d9-a7b0-dbe09763eb98">
                <Col className="vertical-sb" span={24}>
                    <>
                        <Row className="letterspacing-fix" align="middle" justify="center">
                            <Text className="font-title" family="secondary" size="140px" letterSpacing="big" color="light" weight="700">
                                {t('aos.authentic.section1.title')}
                            </Text>
                        </Row>
                        <Row className="letterspacing-fix transform-top-banner" align="middle" justify="center">
                            <Text className="font-title-mirror" size="140px" letterSpacing="big" color="light" weight="bold">
                                {t('aos.authentic.section1.title')}
                            </Text>
                        </Row>
                    </>
                    <Row align="middle" justify="center">
                        <Text size="48px" color="light" weight="600">
                            {t('aos.authentic.section1.cta')}
                        </Text>
                    </Row>
                    <Row style={{ opacity: 0 }} className="flex-full-center fade-in pt-xs" align="middle" justift="center">
                        <img style={sm ? { width: '16px' } : { width: '24px' }} src={MousescrollIcon} alt="scroll-icon" />
                    </Row>
                </Col>
            </Banner>
            <Row className="padding-horizontal pt-img-alt mb-md">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section1.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section1.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-md" align="middle" justify="center">
                <Text color="light" size="36px" weight="700">
                    {t('aos.authentic.section1.text')}
                </Text>
            </Row>
            <Row>
                <Img
                    className={sm && 'sm-banner'}
                    src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs2.jpg?alt=media&token=161aee95-e5e3-43d8-b46f-c6f5a6a388b8"
                />
            </Row>
            <Row className={sm ? 'padding-horizontal mb-sm pt-img-alt' : 'padding-horizontal mb-md'}>
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.authentic.section2.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section2.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs">
                <Text color="light" size="21px" weight="400">
                    {t('aos.authentic.section2.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs3.jpg?alt=media&token=88f6ea80-2af9-460a-9ec0-3cb959ec8cac" />
            </Row>
            <Row className="padding-horizontal mb-md">
                <Col className="pt-img-alt">
                    <Text displayblock color="grey">
                        {t('aos.authentic.section3.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section3.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            {/* Section4 */}
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.authentic.section4.text')}
                        </Text>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs4.jpg?alt=media&token=e7c3f53d-167f-4958-b5e4-91e7b8e7d47b" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section4.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section4.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* Section5 */}
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Row>
                        <Col span={24}>
                            <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs5-1.jpg?alt=media&token=702c7d11-cdbe-4e42-9812-3066638f6c1d" />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.authentic.section5.imgAlt1')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.authentic.section5.imgAlt1Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row>
                        <Text style={{ whiteSpace: 'pre-line' }} color="light" size="21px" weight="400">
                            {t('aos.authentic.section5.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className="eqh-wrapper" span={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs5-2.jpg?alt=media&token=02924a56-0d45-4a41-bc75-08625ebc9562"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section5.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section5.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs6-1.jpg?alt=media&token=8903bfcf-d5a6-4adb-bae1-7c4b29402ffe"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section6.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section6.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs6-2.jpg?alt=media&token=5e9f8b2e-b3f3-48d2-8ed7-997e178d5b12"
                    />
                    <Row className="pt-img-alt eqh-lg-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section6.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section6.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" align="middle" justify="center">
                <Text color="light" size="21px">
                    {t('aos.authentic.section6.text')}
                </Text>
            </Row>
            <Row className="mb-md">
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs7.jpg?alt=media&token=acc76414-747f-43a2-b5f2-f9e876831cfd" />
                <Row className="pt-img-alt padding-horizontal">
                    <Col>
                        <Text displayblock color="grey">
                            {t('aos.authentic.section7.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.authentic.section7.imgAltDesc')}
                        </Text>
                    </Col>
                </Row>
            </Row>
            <Row className="padding-horizontal mb-sm" align="middle" justify="center">
                <Text color="light" size="21px">
                    {t('aos.authentic.section7.text')}
                </Text>
            </Row>
            <Carousel className="mb-sm" type="slider1" data={slider1Data} authentic />
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs9.jpg?alt=media&token=591bb425-55f4-4af4-9eaa-64b969c430d0" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section9.imgAlt')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section9.imgAltDesc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} sm={24} md={12}>
                    <Row className="mb-xs">
                        <Text color="light" size="21px" weight="400">
                            {t('aos.authentic.section9.text')}
                        </Text>
                    </Row>
                    <Row>
                        <Text color="light" size="24px" weight="700">
                            {t('aos.authentic.section9.textDescription')}
                        </Text>
                    </Row>
                    <Row align="middle" className="mb-xs">
                        <Text displayblock color="grey" weight="300">
                            {t('aos.authentic.section9.textDescriptionFade')}
                        </Text>
                    </Row>
                </Col>
            </Row>

            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs12-1.jpg?alt=media&token=ac058319-eaea-4c77-ad61-b170f0c8b0be" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section12.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section12.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs12-2.jpg?alt=media&token=fff104f1-2db1-49b8-b45e-6b2a4cc9654d" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section12.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section12.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} md={12} sm={24}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs13.png?alt=media&token=6ce8a90e-0f05-4df8-99af-20924a5c3dec" />
                    <Col className="pl-reset">
                        <Text displayblock color="grey">
                            {t('aos.authentic.section12.imgAlt3')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.authentic.section12.imgAlt3Desc')}
                        </Text>
                    </Col>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} md={12} sm={24}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.authentic.section12.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs14-1.png?alt=media&token=e437666f-b864-4077-a987-6975353f5515" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section12.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section12.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs14-2.png?alt=media&token=7cefa059-0d38-4dfd-a116-59c1a9c5ee89" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section12.imgAlt5')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section12.imgAlt5Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs15.jpg?alt=media&token=92cc1704-c924-4e7e-b3b2-7fcd258240c8" />
            </Row>
            <Row className="pt-img-alt padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section13.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section13.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs10.jpg?alt=media&token=32e8b8c6-f351-46c9-8e5a-188be6173b6e" />
            </Row>
            <Row className="pt-img-alt padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section10.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section10.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs11.jpg?alt=media&token=f821ca62-37e8-464a-a910-89a0e17b399a" />
            </Row>
            <Row className="pt-img-alt padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section11.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section11.imgAltDesc')}
                    </Text>
                </Col>
            </Row>

            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} md={12} sm={24}>
                    {/* 17 */}
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs17.png?alt=media&token=e51fd6c4-9d18-445c-b1f5-8a367235b77c" />
                    <Col className="pl-reset">
                        <Text displayblock color="grey">
                            {t('aos.authentic.section14.imgAlt3')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.authentic.section14.imgAlt3Desc')}
                        </Text>
                    </Col>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} md={12} sm={24}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.authentic.section14.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    {/* 16-1 */}
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs16-1.png?alt=media&token=15187407-8ee8-4e44-be6f-73fe5c58d7d8" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section14.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section14.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    {/* 16-2 */}
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs16-2.png?alt=media&token=3f601b22-354a-49a9-a714-f51ddf9e613d" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section14.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section14.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs18-2.jpg?alt=media&token=aec1b6f9-22ff-4b28-8ee6-c5bad5ddd0f1" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section14.imgAlt5')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section14.imgAlt5Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs18-1.jpg?alt=media&token=5a247ae0-d0e7-4317-ace9-4544a7014bf0" />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section14.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section14.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm && 'pr-reset'} md={12} sm={24}>
                    <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs19.jpg?alt=media&token=bd8b6f6a-6650-432f-9309-d042b106d369" />
                    <Col className="pl-reset">
                        <Text displayblock color="grey">
                            {t('aos.authentic.section15.imgAlt')}
                        </Text>
                        <Text displayblock color="grey" weight="300">
                            {t('aos.authentic.section15.imgAltDesc')}
                        </Text>
                    </Col>
                </Col>
                <Col className={sm ? 'pl-reset' : 'vertical-center'} md={12} sm={24}>
                    <Row>
                        <Text color="light" size="21px" weight="400">
                            {t('aos.authentic.section15.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Carousel className="mb-xs" type="slider2" data={slider2Data} authentic />
            <Carousel className="mb-xs" type="slider3" data={slider3Data} authentic />
            <Carousel className="mb-sm" type="slider4" data={slider4Data} authentic />
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs19-1.jpg?alt=media&token=3446f802-89a3-4a69-bb98-5973735531e5"
                    />

                    <Row className="eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section19.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section19.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>

                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={8}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs19-2.jpg?alt=media&token=7011f519-4ea0-4bde-89e3-195e244cf9d5"
                    />
                    <Row className="eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section19.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section19.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.authentic.section19.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs23-1.jpg?alt=media&token=1a0bda78-7d5e-46e6-9b64-86d8f08edd0e"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs23-2.jpg?alt=media&token=e5a1d892-9c87-41f9-af2a-a966a94a8f46"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    {/* 23-3 */}
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs23-3.png?alt=media&token=2e03dc9b-1232-4c96-a2ed-bba0783aff24"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs23-4.jpg?alt=media&token=de3d1144-2e36-4ae2-b196-3b3534a4d8ae"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Carousel className="mb-sm" type="slider5" data={slider5Data} authentic />
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px" weight="400">
                    {t('aos.authentic.section22.text')}
                </Text>
            </Row>
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs25-1.jpg?alt=media&token=845ffad6-7808-4ddc-af57-57c1c5fc7aea"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs25-2.jpg?alt=media&token=1e3ee83f-b169-4459-82a8-5bfc9190707d"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section20.imgAlt4')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section20.imgAlt4Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} md={12} sm={24}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs26-1.jpg?alt=media&token=6dd78602-9bf9-441c-bf7c-649086b40746"
                    />
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} md={12} sm={24}>
                    <Row className="mb-xs-static">
                        <Img
                            className="eqh-img"
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs26-2.jpg?alt=media&token=f2681ef8-7052-4414-b165-3e4e16667247"
                        />
                    </Row>
                    <Row className="mb-xs-static">
                        <Img
                            className="eqh-img"
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs26-3.jpg?alt=media&token=ffc2236a-4de2-4c1c-919d-60ea630ff913"
                        />
                    </Row>
                    <Row>
                        <Img
                            className="eqh-img"
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs26-4.jpg?alt=media&token=d73cb51f-84a3-4638-ba1d-8868c774cc1b"
                        />
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal pt-img-alt mb-sm" gutter={[32, 32]}>
                <Col sm={24} md={12}>
                    <Row>
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section23.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section23.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm && 'pl-reset'} sm={24} md={12}>
                    <Row>
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section23.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section23.imgAlt2Desc')}
                            </Text>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section23.imgAlt3')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section23.imgAlt3Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs27-1.jpg?alt=media&token=4e220405-84b8-4c26-8851-fb83f8541883"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section24.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section24.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset' : 'eqh-wrapper'} sm={24} md={12}>
                    <Row className="mb-sm">
                        <Img
                            className="eqh-img"
                            src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs27-2.jpg?alt=media&token=515f9430-ab6d-4a04-af81-2f11508805a9"
                        />
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section24.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section24.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                    <Row align="middle" justify="center">
                        <Text color="light" size="21px">
                            {t('aos.authentic.section24.text')}
                        </Text>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs28.jpg?alt=media&token=b76b0960-f6fc-40af-b42f-b5080bf61b91" />
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section25.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section25.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px">
                    {t('aos.authentic.section25.text')}
                </Text>
            </Row>
            {/* s26 */}
            <Row className="padding-horizontal mb-xs-static" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs29-1.jpg?alt=media&token=c8fdc7a0-772c-4215-9014-5db9f6e51c69"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section26.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section26.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs29-2.jpg?alt=media&token=cf7badc0-ad07-4db5-a76f-53910cee1983"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section26.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section26.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* s27 */}
            <Row className="padding-horizontal mb-xs" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs30-1.jpg?alt=media&token=9ba865d1-fb4b-4441-9040-22c49749a850"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section27.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section27.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs30-2.jpg?alt=media&token=eac13c3b-3f26-45f3-ac7b-c6a733b31221"
                    />
                    <Row className="pt-img-alt eqh-md-text">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section27.imgAlt2')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section27.imgAlt2Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* s28 */}
            <Row className="padding-horizontal mb-md" gutter={[32, 32]}>
                <Col className={sm ? 'pr-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Img
                        className="eqh-img"
                        src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs31-1.jpg?alt=media&token=81336afc-111b-4389-b187-66c811f5cbb1"
                    />
                    <Row className="pt-img-alt">
                        <Col>
                            <Text displayblock color="grey">
                                {t('aos.authentic.section28.imgAlt1')}
                            </Text>
                            <Text displayblock color="grey" weight="300">
                                {t('aos.authentic.section28.imgAlt1Desc')}
                            </Text>
                        </Col>
                    </Row>
                </Col>
                <Col className={sm ? 'pl-reset eqh-wrapper' : 'eqh-wrapper'} sm={24} md={12}>
                    <Row style={{ marginBottom: '14px' }}>
                        <Col span={24}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs30-2.jpg?alt=media&token=eac13c3b-3f26-45f3-ac7b-c6a733b31221"
                            />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.authentic.section28.imgAlt2')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.authentic.section28.imgAlt2Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Img
                                className="eqh-img"
                                src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs31-3.jpg?alt=media&token=07c95e7d-6294-4e52-a5d9-70f35d126f97"
                            />
                            <Row className="pt-img-alt">
                                <Col>
                                    <Text displayblock color="grey">
                                        {t('aos.authentic.section28.imgAlt3')}
                                    </Text>
                                    <Text displayblock color="grey" weight="300">
                                        {t('aos.authentic.section28.imgAlt3Desc')}
                                    </Text>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="padding-horizontal mb-sm">
                <Text color="light" size="21px">
                    {t('aos.authentic.section28.text')}
                </Text>
            </Row>
            <Row>
                <Img src="https://firebasestorage.googleapis.com/v0/b/jewish-quarter-ankara.appspot.com/o/AoS%2F%C3%96zg%C3%BCn%2Fs32.jpg?alt=media&token=814b5b74-22fa-42e4-a00b-8f20cf2e1d39" />
            </Row>
            <Row className="padding-horizontal">
                <Col>
                    <Text displayblock color="grey">
                        {t('aos.authentic.section29.imgAlt')}
                    </Text>
                    <Text displayblock color="grey" weight="300">
                        {t('aos.authentic.section29.imgAltDesc')}
                    </Text>
                </Col>
            </Row>
        </AuthenticPage>
    );
};

export default Authentic;
